import React from 'react'
import { Card, CardSet } from '../shared/cards'
import { Section, Subsection, Subtitle, Title } from './section'

const Forms = ({ className }) => (
  <Section id="forms" className={className}>
    <Title>Forms</Title>

    <Subsection>
      <Subtitle>Features</Subtitle>
      <CardSet storageKey="pricing-forms-features-carousel">
        <Card title="Full-featured form editor">
          Design online forms that are truly yours: select a layout, adjust form
          fields, swap images, and customize buttons.
        </Card>
        <Card title="Mobile-friendly forms">
          Never worry about your mobile audience: forms created in Getform
          easily adapt to screens of all sizes and always look great.
        </Card>
        <Card title="Custom subdomain">
          Incorporate your branding into the page URL to let your audience know
          it belongs to you: shop.getform.com/cake-order-form
        </Card>
        <Card title="All templates">
          Get started faster with a gallery of form templates prepared by
          professional designers and ready to be used.
        </Card>
        <Card title="AI text generator">
          Use AI to generate text for your forms faster. Get smart suggestion
          for the form title, description, and calls to action.
        </Card>
        <Card title="Statictics & reports">
          See how many people have seen and clicked through your forms; build
          activity reports using charts and filters.
        </Card>
        <Card title="Dynamic text replacement">
          Personalize your CTAs using dynamic text. Display your contacts'
          locations, names, and current dates on forms and landing pages.
        </Card>
        <Card title="CSS editor" badge="pro">
          Tailor Getform mini landing pages to your branding with the built-in
          CSS editor. Adjust the sizing, spacing, font, and color theme.
        </Card>
        <Card title="Skip logic and branching" badge="pro">
          Create online forms that adapt each subsequent page to the options
          selected by respondents.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Integrations</Subtitle>
      <CardSet storageKey="pricing-forms-integrations-carousel">
        <Card title="Email marketing apps">
          Connect Getform with Klaviyo, Mailchimp, Convertkit, and other email
          marketing apps.
        </Card>
        <Card title="CRM & automation">
          Make use of built-in integrations with marketing platforms like
          Hubspot and Keap.
        </Card>
        <Card title="Customer experience">
          Optimize your customer experience by connecting Intercom and Omnisend.
        </Card>
        <Card title="Integrations">
          Integrate your online forms and landing page with Google Sheets or
          other apps from the Zapier library.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default Forms
