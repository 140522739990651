import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import BasePlanCarousel from './plan-carousel'
import Plan from './plan'

const PLANS = [
  {
    name: 'Free',
    responses: 100,
    yearlyPrice: 0,
    monthlyPrice: 0,
    features: {
      forms: { pro: false, excluded: false },
      email: { pro: false, excluded: false },
      branding: { pro: false, excluded: true },
    },
  },
  {
    name: 'Pro',
    responses: 1000,
    yearlyPrice: 14,
    monthlyPrice: 19,
    features: {
      forms: { pro: true, excluded: false },
      email: { pro: true, excluded: false },
      branding: { pro: false, excluded: false },
    },
  },
  {
    name: 'Pro Max',
    responses: 10000,
    yearlyPrice: 21,
    monthlyPrice: 29,
    features: {
      forms: { pro: true, excluded: false },
      email: { pro: true, excluded: false },
      branding: { pro: false, excluded: false },
    },
  },
]

const Container = styled.div``

const Grid = styled.div`
  display: none;

  ${mediaQueries.md} {
    display: grid;
    grid-template-columns: minmax(0, 22.5rem);
    grid-row-gap: ${p => p.theme.space[11]};
    grid-column-gap: ${p => p.theme.space[5]};
    justify-content: center;
  }

  ${mediaQueries.lg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-row-gap: ${p => p.theme.space[16]};
    grid-column-gap: ${p => p.theme.space[8]};
  }

  ${mediaQueries.xl} {
    grid-column-gap: ${p => p.theme.space[15]};
  }
`

const PlanCarousel = styled(BasePlanCarousel)`
  ${mediaQueries.md} {
    display: none;
  }
`

const PlanGrid = ({ plans, className }) => (
  <Grid className={className}>
    {plans.map((p, index) => (
      <Plan plan={p} key={index} />
    ))}
  </Grid>
)

const Plans = ({ className }) => (
  <Container className={className}>
    <h2 className="visually-hidden">Pricing plans</h2>
    <PlanGrid plans={PLANS} />
    <PlanCarousel plans={PLANS} />
  </Container>
)

export default Plans
