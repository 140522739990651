import React, { Children } from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { useCarousel } from './carousel'

const Container = styled.div`
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`

const Slides = styled.div`
  display: flex;
  user-select: none;

  ${mediaQueries.md} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(320px, min-content);
    user-select: text;
  }
`

const Slide = styled.div`
  border-top: 2px solid ${p => p.theme.colors.gray[12]};
  border-bottom: 2px solid ${p => p.theme.colors.gray[12]};

  &:nth-of-type(1) {
    border-left: 2px solid ${p => p.theme.colors.gray[12]};
  }

  &:nth-of-type(2) {
    border-right: 1px dashed ${p => p.theme.colors.gray[12]};
    border-left: 1px dashed ${p => p.theme.colors.gray[12]};
  }

  &:nth-of-type(3) {
    border-right: 2px solid ${p => p.theme.colors.gray[12]};
  }
`

const options = {
  desktop: {
    draggable: false,
  },
}

export const CarouselGrid = ({ children, storageKey, className }) => {
  const { slidesRef } = useCarousel(storageKey, options)

  return (
    <Container ref={slidesRef} className={className}>
      <Slides>
        {Children.map(children, (child, index) => (
          <Slide key={index}>{child}</Slide>
        ))}
      </Slides>
    </Container>
  )
}

export default CarouselGrid
