import EmailLinkIcon from 'assets/icons/automations/email-link.svg'
import FormSubmissionIcon from 'assets/icons/automations/form-submission.svg'
import SegmentIcon from 'assets/icons/automations/segment.svg'

const ICONS = {
  email: EmailLinkIcon,
  form: FormSubmissionIcon,
  segment: SegmentIcon,
}

export default ICONS
