import React from 'react'
import styled from '@emotion/styled'
import { Card, CardSet } from '../shared/cards'
import BaseLink from '../shared/link'

const Link = styled(BaseLink)`
  padding: 0;
`

const PricingFAQ = ({ className }) => (
  <CardSet storageKey="pricing-faq-carousel" className={className}>
    <Card title="How much does it cost monthly?">
      Getform offers three subscription plans: Free, Pro ($19/mo), and Pro Max
      ($29/mo). Plans have monthly form response limits and different feature
      sets. You can learn more about the plans on our&nbsp;
      <Link variant="fill" to="/pricing/">
        Pricing page
      </Link>
      .
    </Card>
    <Card title="What happens if I reach the response limit?">
      Once you reach the limit allowed on your plan, your forms will be
      automatically paused. They will no longer collect new submissions until
      the plan is upgraded or the response counter is reset.
    </Card>
    <Card title="Can I switch between plans after I've signed up for one?">
      You can certainly change your subscription plan, as well as a billing
      cycle at any time. If you alter your plan in the middle of a billing
      period, any unused balance will be carried over to future billing periods.
    </Card>
    <Card title="Which payment methods are accepted?">
      We accept PayPal payments and all major credit cards.
    </Card>
    <Card title="How can I get a receipt for my payment?">
      You can download receipts for any of your payments in the Billing section
      of your account.
    </Card>
  </CardSet>
)

export default PricingFAQ
