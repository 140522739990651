import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import ArtDirectedImage from '../art-directed-image'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${p =>
    `${p.theme.space[8]} calc(${p.theme.space[8]} - 2px) 0 calc(${p.theme.space[8]} - 2px)`};
  background-color: ${p => p.theme.colors.white};
  border: 2px solid ${p => p.theme.colors.gray[12]};

  ${mediaQueries.md} {
    padding: ${p =>
      `${p.theme.space[10]} calc(${p.theme.space[10]} - 2px) 0 calc(${p.theme.space[10]} - 2px)`};
  }

  &.padded {
    padding-bottom: ${p => `calc(${p.theme.space[8]} - 2px)`};

    ${mediaQueries.md} {
      padding-bottom: ${p => `calc(${p.theme.space[10]} - 2px)`};
    }
  }
`

const Title = styled.h3`
  margin: 0 0 ${p => p.theme.space[3]} 0;
  font-size: ${p => p.theme.fontSizes[4]};
  line-height: 1.4;
`

const Description = styled.p`
  max-width: 28rem;
  margin: 0 0 ${p => p.theme.space[6]} 0;
  font-size: ${p => p.theme.fontSizes[1]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[8]};
  }
`

const MediaImage = styled(ArtDirectedImage)`
  margin-top: auto;
`

export const MediaCard = ({
  title,
  description,
  imageDesktop,
  imageMobile,
  imagesAlt,
  className,
  imageClass,
}) => {
  return (
    <Card className={className}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <MediaImage
        desktop={imageDesktop}
        mobile={imageMobile}
        alt={imagesAlt || title}
        className={imageClass}
      />
    </Card>
  )
}
